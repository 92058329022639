<template>
  <div class="bg-[#F2F6FA] min-h-screen py-8 w-full overflow-x-hidden">
    <div class="container mx-auto w-full px-4 md:px-8 lg:px-12 xl:px-16 max-w-[1285px]">
      <!-- Header -->
      <div class="flex items-center gap-4 mb-6 px-4">
        <v-btn icon @click="$router.go(-1)" class="bg-white">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <h1 class="text-2xl font-bold">Update GMB Profiles</h1>
      </div>

      <!-- Add this button section below the header -->
      <div class="mb-6 flex flex-col gap-4 px-4">
        <!-- Top row with search and action buttons -->
        <div class="flex flex-col md:flex-row justify-between items-center">
          <div class="flex flex-col md:flex-row gap-4 items-center w-full">
            <input v-model="searchQuery" type="text" placeholder="Search locations..."
              class="form-input w-full md:w-80 bg-white shadow-md mb-4 md:mb-0" />

            <div class="flex flex-col sm:flex-row gap-4 w-full md:w-auto">
              <v-btn color="primary" @click="fetchGMBData()" :loading="isFetching" :disabled="!canFetchLatestData||isFetching"
                class="w-full sm:w-auto -mt-2">
                <v-icon left>mdi-refresh</v-icon>
                <span v-if="canFetchLatestData">Fetch Latest GMB Data</span>
                <span v-else>Refresh available in {{ refreshAvailableIn }} hours</span>
              </v-btn>

              <v-btn color="secondary" @click="showBulkUpdate = !showBulkUpdate" class="w-full sm:w-auto -mt-2">
                <v-icon left>{{ showBulkUpdate ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                Bulk Update
              </v-btn>
            </div>
          </div>
        </div>

        <!-- Strength filters below -->
        <div class="status-filters">
          <button v-for="filter in strengthFilters" :key="filter.value" @click="strengthFilter = filter.value"
            class="status-filter-btn" :class="[
              {
                'status-excellent': filter.value === 'excellent',
                'status-good': filter.value === 'good',
                'status-average': filter.value === 'average',
                'status-poor': filter.value === 'poor',
                'status-critical': filter.value === 'critical'
              },
              { active: strengthFilter === filter.value }
            ]">
            {{ filter.label }} ({{ getFilterCount(filter.value) }})
          </button>
        </div>
      </div>

      <!-- Note -->
      <div class="mb-6 px-4">
        <p
          class="flex items-center text-gray-800 bg-gradient-to-r from-blue-50 to-indigo-50 p-4 rounded-md border-l-4 border-blue-500 shadow-sm">
          <v-icon color="blue" class="mr-3">mdi-information</v-icon>
          <span class="font-medium">
            To keep your business listing at the top, please ensure all the information you provide is accurate.
          </span>
        </p>
      </div>

      <!-- Add this note section after the existing note -->
      <div class="mb-6 px-4">
        <div v-if="!checkUpdateEligibility(gmb_bulk_last_updated).canUpdate"
          class="flex items-center text-gray-800 bg-yellow-50 p-4 rounded-md border-l-4 border-yellow-500 shadow-sm">
          <v-icon color="warning" class="mr-3">mdi-clock-alert</v-icon>
          <span class="font-medium">
            Bulk updates will be available in {{ checkUpdateEligibility(gmb_bulk_last_updated).remainingTime }} hours
          </span>
        </div>
      </div>

      <!-- Bulk Update Section -->
      <div v-if="showBulkUpdate" class="mb-6 px-4">
        <div class="bg-white p-4 rounded-lg shadow-sm">
          <h3 class="text-lg font-medium mb-4">Bulk Update All Locations</h3>

          <!-- Category Info -->

          <div class="grid md:grid-cols-3 gap-4">
            <!-- Website URL -->
            <div class="form-group">
              <label class="form-label">Website URL</label>
              <input type="url" class="form-input" placeholder="https://example.com" v-model="bulkUpdate.websiteUri" />
            </div>

            <!-- Phone Number -->
            <div class="form-group">
              <label class="form-label">Phone Number</label>
              <input type="tel" class="form-input" placeholder="+911234567890" v-model="bulkUpdate.phoneNumber" />
            </div>
          </div>

          <!-- Description on its own row -->
          <div class="mb-4">
            <div class="form-group max-w-2xl">
              <label class="form-label">Description</label>
              <div class="space-y-2">
                <!-- Keywords display for bulk update -->
                <div class="flex flex-wrap gap-2 items-center mb-2">
                  <div v-for="(keyword, index) in bulkKeywords" :key="index" class="keyword-chip"
                    :style="{ width: `${keyword.length * 10 + 40}px` }">
                    <span class="text-sm truncate">{{ keyword }}</span>
                    <button @click="removeBulkKeyword(index)" class="flex-shrink-0">
                      <v-icon small>mdi-close</v-icon>
                    </button>
                  </div>
                </div>

                <!-- Keywords input for bulk update -->
                <div class="space-y-2">
                  <div class="flex gap-2">
                    <input v-model="currentBulkKeyword" @keyup.enter="addBulkKeyword" type="text"
                      placeholder="Add keywords and press Enter" class="form-input flex-1" />
                  </div>

                  <div class="flex gap-2">
                    <v-btn small color="secondary" @click="addBulkKeyword">
                      <v-icon small left>mdi-plus</v-icon>
                      Add Keyword
                    </v-btn>

                    <v-btn small color="primary" @click="generateBulkDescription" :loading="generatingBulkDescription"
                      :disabled="generatingBulkDescription || !locations.length">
                      <v-icon small left>mdi-refresh</v-icon>
                      Generate Description
                    </v-btn>
                  </div>
                </div>

                <textarea class="form-input" rows="3" v-model="bulkUpdate.description"
                  placeholder="Enter description or generate one automatically"></textarea>
              </div>
            </div>
          </div>

          <!-- Single Apply Button -->
          <div class="mt-4 flex justify-end">
            <v-btn color="primary" @click="applyBulkUpdates" :loading="bulkUpdating"
              :disabled="bulkUpdating || (!bulkUpdate.websiteUri && !bulkUpdate.phoneNumber && !bulkUpdate.description) || !checkUpdateEligibility(this.gmb_bulk_last_updated).canUpdate">
              Apply All Updates
            </v-btn>
          </div>
        </div>
      </div>

      <!-- Main Content -->
      <div class="bg-white rounded-lg shadow-sm p-6 mb-8 mr-[0.05rem]">
        <div v-if="loading" class="flex flex-col justify-center items-center h-64">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <p class="mt-2 text-gray-600">Fetching data from GMB could take a few minutes</p>
        </div>

        <div v-else>
          <!-- Locations List -->
          <div v-if="!locations.length" class="flex flex-col justify-center items-center h-64">
            <p class="text-gray-600">No locations available. Please add locations to manage your GMB profiles.</p>
          </div>
          <div class="space-y-6" v-else>
            <div v-for="location in paginatedLocations" :key="location?.id" class="border rounded-lg p-4">
              <!-- Location Info -->
              <div class="flex justify-between items-start mb-4">
                <!-- Add verification state chip to the right -->
                <div class="space-y-3 w-full">
                  <!-- Basic Info -->
                  <div class="flex justify-between items-center">
                    <div>
                      <h3 class="font-semibold text-lg">{{ location?.title }}</h3>
                      <p class="text-gray-600">{{ location?.storefrontAddress?.addressLines?.[0]?.split(',')[0] }}, {{
                        location?.storefrontAddress?.locality }}, {{ location?.storefrontAddress?.administrativeArea }}
                      </p>
                    </div>

                    <div class="flex justify-end items-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <button class="ml-4" v-on="on" @click="fetchGMBData(location?.workspaceId)">
                            <v-icon medium>mdi-refresh</v-icon>
                          </button>
                        </template>
                        <span>Refresh latest gmb data</span>
                      </v-tooltip>
                      <v-chip small :color="getVerificationStateColor(location?.verificationState)" text-color="white"
                        class="ml-2">
                        {{ formatVerificationState(location?.verificationState) }}
                      </v-chip>
                      <!-- Refresh Button -->
                    </div>
                  </div>

                  <!-- Categories -->
                  <div>
                    <span class="text-sm font-medium text-gray-600">Categories:</span>
                    <div class="flex flex-wrap gap-2 mt-1">
                      <!-- Primary Category -->
                      <v-chip small color="primary" text-color="white" class="text-sm">
                        {{ location?.categories?.primaryCategory?.displayName || 'No primary category' }}
                      </v-chip>

                      <!-- Additional Categories -->
                      <template v-if="location?.categories?.additionalCategories?.length">
                        <v-chip v-for="(category, index) in location?.categories.additionalCategories" :key="index" small
                          color="secondary" text-color="white" class="text-sm">
                          {{ category.displayName }}
                        </v-chip>
                      </template>
                    </div>
                  </div>

                  <!-- Description -->
                  <div>
                    <span class="text-sm font-medium text-gray-600">Description:</span>

                    <!-- Loading state -->
                    <div v-if="location?.generatingDescription" class="mt-4 flex justify-center">
                      <v-progress-circular indeterminate color="primary" size="24"></v-progress-circular>
                      <span class="ml-2 text-sm text-gray-600">Generating optimized description...</span>
                    </div>

                    <!-- Add keywords input field -->
                    <div v-else-if="!location.newDescription" class="space-y-2">
                      <p class="text-sm mt-1 text-gray-700">
                        {{ location?.profile?.description || 'No description available' }}
                      </p>

                      <!-- Keywords input -->
                      <div class="flex flex-wrap gap-2 items-center">
                        <div v-for="(keyword, index) in location.keywords" :key="index" class="keyword-chip"
                          :style="{ width: `${keyword.length * 10 + 40}px` }">
                          <span class="text-sm truncate">{{ keyword }}</span>
                          <button @click="removeKeyword(location, index)" class="flex-shrink-0">
                            <v-icon small>mdi-close</v-icon>
                          </button>
                        </div>
                      </div>

                      <div class="flex gap-2">
                        <input v-model="location.currentKeyword" @keyup.enter="addKeyword(location)" type="text"
                          placeholder="Add keywords and press Enter" class="form-input flex-1" />
                      </div>

                      <div class="flex gap-2 mt-2">
                        <v-btn small color="secondary" @click="addKeyword(location)">
                          <v-icon small left>mdi-plus</v-icon>
                          Add Keyword
                        </v-btn>

                        <v-btn small color="primary" :disabled="location?.generatingDescription"
                          @click="generateDescription(location)">
                          <v-icon small left>mdi-refresh</v-icon>
                          <span class="text-xs sm:text-sm">Generate Description</span>
                        </v-btn>
                      </div>
                    </div>

                    <!-- Modified comparison view with apply and cancel buttons -->
                    <div v-else class="mt-2 space-y-4">
                      <!-- Side by side comparison on larger screens -->
                      <div class="grid md:grid-cols-2 gap-4">
                        <!-- Old description -->
                        <div class="bg-red-50 p-4 rounded-lg border border-red-100">
                          <div class="text-sm font-medium text-red-600 mb-2">Current Description:</div>
                          <p class="text-sm text-gray-700 whitespace-pre-wrap">
                            {{ location?.profile?.description || 'No description available' }}
                          </p>
                        </div>

                        <!-- New description -->
                        <div class="bg-green-50 p-4 rounded-lg border border-green-100">
                          <div class="text-sm font-medium text-green-600 mb-2">Optimized Description:</div>
                          <p class="text-sm text-gray-700 whitespace-pre-wrap">
                            {{ location?.newDescription }}
                          </p>
                        </div>
                      </div>

                      <!-- Action buttons -->
                      <div class="flex justify-end gap-2">
                        <v-btn small color="primary" @click="updateProfile(location)" :loading="location.updating"
                          :disabled="location.updating || !checkUpdateEligibility(location.gmb_last_updated).canUpdate">
                          <v-icon small left>mdi-check</v-icon>
                          Apply
                        </v-btn>
                        <v-btn small outlined @click="location.newDescription = null">
                          <v-icon small left>mdi-close</v-icon>
                          Cancel
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <!-- Profile Strength -->
                  <div class="mt-2 space-y-2">
                    <!-- Overall Profile Strength with Link Icon -->
                    <div class="flex items-center gap-2 mb-4">
                      <div class="w-24 h-2 bg-gray-200 rounded-full overflow-hidden">
                        <div class="h-full rounded-full" :style="{
                          width: `${getProfileStrength(location).total}%`,
                          backgroundColor: getProfileStrengthColor(location)
                        }"></div>
                      </div>
                      <span class="text-sm text-gray-600">
                        Profile Strength: {{ getProfileStrength(location).total }}%
                      </span>
                      <!-- Add link icon for low strength profiles -->
                      <a v-if="getProfileStrength(location).total < 60" :href="getGMBUrl(location)" target="_blank"
                        rel="noopener noreferrer"
                        class="inline-flex items-center gap-1 text-sm text-[#8050DE] hover:text-[#6040AE]"
                        title="Complete your profile on Google Business">
                        <v-icon small>mdi-link</v-icon>
                        <span>View Your Profile</span>
                      </a>
                    </div>

                    <!-- Individual Field Status -->
                    <div class="space-y-3">
                      <!-- Basic Info Fields -->
                      <div v-for="(value, field) in getFieldStatus(location).basicInfo" :key="field"
                        class="flex items-center justify-between">
                        <div class="flex items-center gap-2">
                          <span class="w-2 h-2 rounded-full" :class="value ? 'bg-green-500' : 'bg-red-500'"></span>
                          <span class="text-sm">{{ formatFieldName(field) }}</span>
                        </div>
                        <div class="w-24 h-1.5 bg-gray-200 rounded-full overflow-hidden">
                          <div class="h-full rounded-full" :class="value ? 'bg-green-500' : 'bg-red-200'"
                            :style="{ width: value ? '100%' : '0%' }"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- GMB Profile Form -->
              <form @submit.prevent="updateProfile(location)" class="space-y-6">
                <div class="grid md:grid-cols-2 gap-6">
                  <div class="form-group">
                    <label class="form-label">Business Name</label>
                    <input v-model="location.formData.businessName" type="text"
                      class="form-input bg-gray-50 cursor-not-allowed" disabled />
                  </div>

                  <!-- Update the phone input field -->
                  <div class="form-group">
                    <label class="form-label">Primary Phone</label>


                    <vue-tel-input-vuetify v-model="location.formData.primaryPhone" type="tel"
                      class="w-full relative rounded box-border h-[42px] border-[1px] border-solid border-stroke gap-3 primary-phone"
                      @input="(event) => formatPhoneNumber(event, location)" :placeholder="''"
                      :inputOptions="{ placeholder: '' }" :dropdownOptions="{ placeholder: '' }" :label="''"
                      hide-details :defaultCountry="'IN'" :enabledCountryCode="true"></vue-tel-input-vuetify>

                  </div>
                </div>

                <!-- Second Row: Website URL and Business Hours Toggle -->
                <div class="grid md:grid-cols-2 gap-6">
                  <div class="form-group">
                    <label class="form-label">Website URL</label>
                    <input v-model="location.formData.websiteUri" type="url" class="form-input"
                      placeholder="https://example.com" />
                  </div>

                  <!-- Primary Category -->
                  <div class="form-group">
                    <label class="form-label">Primary Category</label>
                    <input v-model="location.formData.primaryCategory" type="text"
                      class="form-input bg-gray-50 cursor-not-allowed" disabled />
                  </div>
                </div>

                <!-- Additional Categories Section -->
                <div class="form-group mt-4">
                  <label class="form-label flex items-center justify-between">
                    <span>Additional Categories</span>
                    <span class="text-sm text-gray-600">({{ location.formData.additionalCategories?.length || 0
                      }}/9)</span>
                  </label>

                  <!-- Display selected additional categories -->
                  <div class="flex flex-wrap gap-2 mb-2">
                    <v-chip v-for="(category, index) in location.formData.additionalCategories" :key="index"
                      :close="!category.isOriginal" @click:close="removeAdditionalCategory(location, index)"
                      class="mr-2 mb-2" small :class="!category.isOriginal ? 'white category-chip-new' : null">
                      {{ category.displayName }}
                    </v-chip>
                  </div>

                  <!-- Add the suggest categories button -->
                  <div class="flex gap-2 mb-2">
                    <v-btn small color="success" @click="suggestCategories(location)"
                      :loading="location.suggestingCategories" :disabled="location.suggestingCategories">
                      <v-icon small left>mdi-lightbulb</v-icon>
                      Suggest Categories
                    </v-btn>
                  </div>

                  <!-- Dropdown for adding new categories -->
                  <v-autocomplete v-model="location.selectedAdditionalCategory"
                    :items="filteredAdditionalCategories(location)" item-text="displayName" item-value="name"
                    :loading="location.loadingCategories"
                    :disabled="location.formData.additionalCategories?.length >= 9"
                    placeholder="Search and add categories..." :search-input.sync="location.categorySearchQuery"
                    outlined dense hide-details @change="addAdditionalCategory(location)" class="category-dropdown"
                    :no-data-text="location.categorySearchQuery ? 'No matching categories found' : 'Start typing to search categories'"></v-autocomplete>
                </div>

                <!-- Business Hours Section -->
                <div v-if="location.showHours" class="border rounded-lg p-4 space-y-4">
                  <div v-for="day in weekDays" :key="day.value" class="grid grid-cols-12 gap-4 items-center">
                    <div class="col-span-2">
                      <label class="form-label">{{ day.label }}</label>
                      <v-switch v-model="location.formData.hours[day.value].isOpen" dense hide-details
                        class="mt-0"></v-switch>
                    </div>

                    <div v-if="location.formData.hours[day.value].isOpen" class="col-span-10 grid grid-cols-2 gap-4">
                      <div class="form-group mb-0">
                        <v-menu ref="menu" v-model="location.formData.hours[day.value].openMenu"
                          :close-on-content-click="false" :return-value.sync="location.formData.hours[day.value].open"
                          transition="scale-transition" offset-y max-width="290px" min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="location.formData.hours[day.value].open" label="Opening Time"
                              readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                          </template>
                          <v-time-picker v-model="location.formData.hours[day.value].open" full-width
                            @click:minute="$refs.menu.save(location.formData.hours[day.value].open)"></v-time-picker>
                        </v-menu>
                      </div>

                      <div class="form-group mb-0">
                        <v-menu ref="menu" v-model="location.formData.hours[day.value].closeMenu"
                          :close-on-content-click="false" :return-value.sync="location.formData.hours[day.value].close"
                          transition="scale-transition" offset-y max-width="290px" min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="location.formData.hours[day.value].close" label="Closing Time"
                              readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                          </template>
                          <v-time-picker v-model="location.formData.hours[day.value].close" full-width
                            @click:minute="$refs.menu.save(location.formData.hours[day.value].close)"></v-time-picker>
                        </v-menu>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Add this inside the location card, just before the form -->
                <div v-if="!getLocationUpdateEligibility(location).canUpdate"
                  class="mb-4 flex items-center text-gray-800 bg-yellow-50 p-4 rounded-md border-l-4 border-yellow-500 shadow-sm">
                  <v-icon color="warning" class="mr-3">mdi-clock-alert</v-icon>
                  <span class="font-medium">
                    Profile updates will be available in {{ getLocationUpdateEligibility(location).remainingTime }}
                    hours
                  </span>
                </div>

                <!-- Update the form's submit button to be disabled when updates aren't allowed -->
                <div class="flex justify-end">
                  <v-btn type="submit" color="primary" :loading="location.updating"
                    :disabled="location.updating || !getLocationUpdateEligibility(location).canUpdate">
                    <span v-if="getLocationUpdateEligibility(location).canUpdate">Update Profile</span>
                    <span v-else>Update Available in {{ getLocationUpdateEligibility(location).remainingTime }}h</span>
                  </v-btn>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- Pagination Controls -->
      <div class="flex justify-between mt-4">
        <button @click="prevPage" :disabled="currentPage === 1" class="pagination-button">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="pagination-button">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/utils/firebase.utils';
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc, collection, getDocs, deleteDoc, query, where, orderBy, limit, startAt, startAfter } from 'firebase/firestore';
import { db } from '@/utils/firebase.utils'; // Ensure you have a Firestore instance

export default {
  name: 'UpdateGMBProfile',

  data() {
    return {
      updateTimeLimit: 24,
      loading: false,
      locations: [],
      categories: [],
      categoriesId: [],
      searchQuery: '',
      isFetching: false,
      showBulkUpdate: false,
      bulkUpdate: {
        websiteUri: '',
        phoneNumber: '',
        description: ''
      },
      workspacesData: [],
      gmb_bulk_last_updated: null,
      gmb_bulk_last_refreshed: null,
      bulkUpdating: false,
      bulkGeneratingDescriptions: false,
      strengthFilter: 'all',
      strengthFilters: [
        {
          label: 'All',
          value: 'all',
          bgClass: 'bg-gray-500',
          condition: () => true
        },
        {
          label: 'Excellent',
          value: 'excellent',
          bgClass: 'bg-[#4CAF50]',
          condition: (strength) => strength >= 85
        },
        {
          label: 'Good',
          value: 'good',
          bgClass: 'bg-[#8BC34A]',
          condition: (strength) => strength >= 70 && strength < 85
        },
        {
          label: 'Average',
          value: 'average',
          bgClass: 'bg-[#FFA726]',
          condition: (strength) => strength >= 50 && strength < 70
        },
        {
          label: 'Poor',
          value: 'poor',
          bgClass: 'bg-[#FF7043]',
          condition: (strength) => strength >= 30 && strength < 50
        },
        {
          label: 'Critical',
          value: 'critical',
          bgClass: 'bg-[#EF5350]',
          condition: (strength) => strength < 30
        }
      ],
      bulkKeywords: [],
      currentBulkKeyword: '',
      generatingBulkDescription: false,
      currentPage: 1,
      totalPages: 1,
      itemsPerPage: 5, // Number of locations to display per page
    };
  },

  computed: {
    filteredLocations() {
      let filtered = this.locations;

      // Apply search filter
      if (this.searchQuery.trim()) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter((location) => {
          return (
            location?.storeLocation?.toLowerCase().includes(query)
          );
        });
      }

      // Apply strength filter
      if (this.strengthFilter !== 'all') {
        const filter = this.strengthFilters.find(f => f.value === this.strengthFilter);
        filtered = filtered.filter(location => {
          const strength = this.getProfileStrength(location).total;
          return filter.condition(strength);
        });
      }

      return filtered;
    },

    commonCategories() {
      if (!this.locations.length) return [];

      // Get all categories from first location
      const firstLocation = this.locations[0];
      const firstLocationCategories = new Set([
        firstLocation?.categories?.primaryCategory?.displayName,
        ...(firstLocation?.categories?.additionalCategories?.map(cat => cat.displayName) || [])
      ].filter(Boolean));

      // Check if all other locations have the same categories
      const commonCategories = Array.from(firstLocationCategories).filter(category => {
        return this.locations.every(location => {
          const locationCategories = new Set([
            location?.categories?.primaryCategory?.displayName,
            ...(location?.categories?.additionalCategories?.map(cat => cat.displayName) || [])
          ].filter(Boolean));
          return locationCategories.has(category);
        });
      });

      return commonCategories;
    },

    paginatedLocations() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.filteredLocations.slice(start, start + this.itemsPerPage);
    },

    canFetchLatestData() {
      const now = new Date();
      const lastRefreshed = this.gmb_bulk_last_refreshed instanceof Date ? this.gmb_bulk_last_refreshed : this.gmb_bulk_last_refreshed ? this.gmb_bulk_last_refreshed.toDate() : null;

      if (!lastRefreshed) {
        return true; // If there's no last refreshed date, allow fetching
      }

      const diffHours = (now - lastRefreshed) / (1000 * 60 * 60);
      return diffHours >= 24;
    },
    refreshAvailableIn() {
      const now = new Date();
      const lastRefreshed = this.gmb_bulk_last_refreshed instanceof Date ? this.gmb_bulk_last_refreshed : this.gmb_bulk_last_refreshed ? this.gmb_bulk_last_refreshed.toDate() : null;

      if (!lastRefreshed) {
        return 0; // If there's no last refreshed date, indicate refresh is available immediately
      }

      const diffHours = (now - lastRefreshed) / (1000 * 60 * 60);
      const remainingHours = Math.max(0, 24 - diffHours);
      return Math.floor(remainingHours);
    }
  },

  async created() {
    // Initial fetch of GMB data
    await this.fetchLastUpdatedAndRefreshed();
    await this.fetchWorkspaceData();
    await this.fetchGMBCategories();
    // await this.fetchGMBData();
  },

  methods: {
    async fetchWorkspaceData(workspaceID) {
      try {
        if (workspaceID) {
          console.log(workspaceID, "updating for a specific workspace");
          const workspaceDoc = doc(db, "users", this.currentUID, "fly_workspaces", workspaceID);

          try {
            const snapshot = await getDoc(workspaceDoc);

            if (snapshot.exists()) {
              const updatedLocation = snapshot.data()?.gmbProfile;

              // Skip if gmbProfile is not present
              if (!updatedLocation) {
                console.log(`No gmbProfile found for workspace ID ${workspaceID}, skipping.`);
                return;
              }

              // Update the specific location in this.locations
              const index = this.locations.findIndex(location => location.workspaceId === workspaceID);
              if (index !== -1) {
                console.log(this.locations[index], "ws index");

                this.$set(this.locations, index, {
                  ...this.locations[index],
                  ...updatedLocation,
                  formData: {
                    businessName: updatedLocation?.title || '',
                    primaryPhone: this.formatFetchedPhoneNumber(updatedLocation?.phoneNumbers?.primaryPhone || ''),
                    primaryCategory: updatedLocation?.categories?.primaryCategory?.displayName || '',
                    websiteUri: updatedLocation?.websiteUri || '',
                    description: updatedLocation?.profile?.description || '',
                    additionalCategories: (updatedLocation?.categories?.additionalCategories || []).map((cat) => ({
                      ...cat,
                      isOriginal: true, // Mark original categories
                    })),
                  },
                });
              }
            }
          } catch (error) {
            console.error(`Error fetching workspace document for ID ${workspaceID}:`, error);
          }
        } else {
          this.loading = true;

          try {
            let collectionRef = query(
              collection(db, "users", this.currentUID, "fly_workspaces"),
              where("storeWorkspace", "==", true),
              where("admin", "==", this.WID)
            );

            let snapshot = await getDocs(collectionRef);

            this.totalPages = Math.ceil(snapshot.docs.length / this.itemsPerPage);
            collectionRef = query(
              collection(db, "users", this.currentUID, "fly_workspaces"),
              where("storeWorkspace", "==", true),
              where("admin", "==", this.WID)
            );

            snapshot = await getDocs(collectionRef);
            this.totalMembers = snapshot.docs.length; // Get total members count

            // Fetch all members
            const locations = await Promise.all(
              snapshot.docs.map(async (docSnapshot) => {
                try {
                  let location = docSnapshot.data()?.gmbProfile;

                  // Skip if gmbProfile is not present
                  if (!location) {
                    console.log(`No gmbProfile found for document ID ${docSnapshot?.id}, skipping.`);
                    return null;
                  }

                  const profileData = {
                    ...location,
                    workspaceId: docSnapshot?.id,
                    storeLocation: docSnapshot.data()?.storeLocation || "",
                    formData: {
                      businessName: location?.title || '',
                      primaryPhone: this.formatFetchedPhoneNumber(location?.phoneNumbers?.primaryPhone || ''),
                      primaryCategory: location?.categories?.primaryCategory?.displayName || '',
                      websiteUri: location?.websiteUri || '',
                      description: location?.profile?.description,
                      additionalCategories: (location?.categories?.additionalCategories || []).map((cat) => ({
                        ...cat,
                        isOriginal: true, // Mark original categories
                      })),
                    },
                    gmb_last_updated: docSnapshot.data()?.gmb_last_updated || null,
                    keywords: docSnapshot.data()?.gmbKeywords || null,
                    currentKeyword: '',
                    showHours: false,
                    updating: false,
                    generatingDescription: false,
                    applyingDescription: false,
                    newDescription: null,
                    selectedAdditionalCategory: null,
                    categorySearchQuery: '',
                    suggestingCategories: false,
                  };
                  return profileData; // Return the member data
                } catch (error) {
                  console.error(`Error processing document ${docSnapshot?.id}:`, error);
                  return null; // Skip on error
                }
              })
            );

            // Filter out null values from locations
            this.locations = locations.filter(location => location !== null);

            console.log(this.locations, "these are filtered locations");
          } catch (error) {
            console.error("Error fetching collection:", error);
          } finally {
            this.loading = false;
          }
        }
      } catch (error) {
        console.error("Error in fetchWorkspaceData function:", error);
      }
    },

    async fetchGMBCategories() {
      try {
        const gmbCategories = await fetch("https://firebasestorage.googleapis.com/v0/b/around-dev-1.appspot.com/o/gmb-categories%2Fgmb-categories.json?alt=media&token=cccd1123-19ac-4d68-a46a-56da6a0e481d")
        this.categories = await gmbCategories.json();
        this.categoriesId = this.categories.map((item) => item.name);
      } catch (error) {
        console.error('Error fetching GMB data:', error);
        this.$alert.show('Error fetching GMB data');
      }
    },
    async fetchLastUpdatedAndRefreshed() {
      const workspaceDoc = doc(db, `users/${this.currentUID}/fly_workspaces/${this.WID}`);
      const workspaceSnapshot = await getDoc(workspaceDoc);
      if (workspaceSnapshot.exists()) {
        this.gmb_bulk_last_updated = workspaceSnapshot.data().gmb_bulk_last_updated;
        this.gmb_bulk_last_refreshed = workspaceSnapshot.data().gmb_bulk_last_refreshed;
      }
    },
    async fetchGMBData(workspaceID) {
      // return ;
      console.log(workspaceID, "fetching for workspace");
      // return ;
      if(!workspaceID){
        this.loading = true;
      }
      this.isFetching = true;
      if(!workspaceID){
        const workspaceRef = doc(db, `users/${this.currentUID}/fly_workspaces/${this.WID}`);
        this.gmb_bulk_last_refreshed = new Date();
        await updateDoc(workspaceRef,{gmb_bulk_last_refreshed:this.gmb_bulk_last_refreshed});
      }
      try {
        const fetchData = httpsCallable(functions, "fetchGMBDataFunction");
        const res = await fetchData({
          uid: this.currentUID,
          wid: this.WID,
          workspaceID
        });
        console.log(res.data,"this is res.data");
        if (res.data.success) {
          if (workspaceID) {
            await this.fetchWorkspaceData(workspaceID);
          }
          else await this.fetchWorkspaceData();
          this.$snackbar.show('GMB data fetched successfully');
        }
        else {
          this.$snackbar.show("Couldn't fetch latest gmb data");
        }
        // console.log("Locations updated:", res.data.all);
      } catch (error) {
        console.error("Error fetching GMB data:", error);
        this.$alert.show("Error fetching GMB data");
      } finally {
        this.isFetching = false;
        this.loading = false;
      }
    },


    formatFetchedPhoneNumber(phone) {
      // Remove any existing +91, leading zeros, or spaces
      const cleanNumber = phone.replace(/^\+91|^91|^0+|\s+/g, '').trim();
      // Add +91 prefix if not empty
      return cleanNumber ? `+91${cleanNumber}` : '';
    },
    formatPhoneNumber(event, location) {
      if (!location || !location.formData) return;
      let value = '';
      if (event && typeof event === 'object') {
        // Use the formatted number which includes country code
        value = event.formattedNumber || '';
      } else if (typeof event === 'string') {
        value = event;
      }
      // Remove any non-digit characters except + at the start
      value = value.replace(/[^\d+]/g, '');
      // Update the model
      location.formData.primaryPhone = value;
    },

    async updateProfile(location) {
      location.updating = true;
      try {
        // Format phone number before sending
        const formattedPhone = location.formData.primaryPhone;
        const updateFields = {};

        // Check if phone number has changed (including empty values)
        if (formattedPhone !== this.formatFetchedPhoneNumber(location.phoneNumbers?.primaryPhone)) {
          updateFields.phoneNumbers = {
            primaryPhone: formattedPhone
          };
        }

        // Check if website has changed (including empty values)
        if ((location.formData.websiteUri || location.websiteUri) && location.formData.websiteUri !== location.websiteUri) {
          updateFields.websiteUri = location.formData.websiteUri;
        }

        // Check if description has changed (including empty values)
        if (location.newDescription || location.formData.description !== location.profile?.description) {
          updateFields.profile = {
            description: location.newDescription || location.formData.description
          };
        }

        // Add check for additional categories changes
        const originalCategories = location.categories?.additionalCategories?.map(cat => cat.name) || [];
        const newCategories = location.formData.additionalCategories?.map(cat => cat.name) || [];

        if (!this.arraysEqual(originalCategories, newCategories)) {
          updateFields.categories = {
            primaryCategory: {
              name: location.categories.primaryCategory.name,
            },
            additionalCategories: newCategories
          };
        }

        // Only proceed if there are fields to update
        if (Object.keys(updateFields).length === 0) {
          this.$snackbar.show('No changes to update');
          return;
        }

        const payload = {
          uid: this.currentUID,
          updates: [{
            locationId: location.name,
            updateFields,
            wid: location.workspaceId,
            accountId: location.accountId
          }]
        };
        const updateGMBProfile = httpsCallable(functions, "updateGMBDataFunction");
        const res = await updateGMBProfile(payload);

        if (res?.data?.results) {
          // Update the profile description and clear newDescription
          const currDate = new Date();
          const workspaceRef = doc(db, `users/${this.currentUID}/fly_workspaces/${location.workspaceId}`);
          await updateDoc(workspaceRef, {
            gmb_last_updated: currDate
          });
          this.$snackbar.show('Profile updated successfully');
        }
      } catch (error) {
        console.error('Error updating profile:', error);
        this.$alert.show('Error updating profile');
      } finally {
        location.updating = false;
      }
    },


    formatVerificationState(state) {
      if (!state) return 'Not Verified';
      if (state === 'COMPLETED') return 'Verified';
      return state.split('_')
        .map(word => word.charAt(0) + word.slice(1).toLowerCase())
        .join(' ');
    },

    getVerificationStateColor(state) {
      const colors = {
        'VERIFIED': 'success',
        'COMPLETED': 'success',
        'PENDING': 'warning',
        'FAILED': 'error',
        'STATE_UNSPECIFIED': 'grey'
      };
      return colors[state] || 'grey';
    },

    getProfileStrength(location) {
      // Define all fields we want to check
      const fields = {
        businessName: !!location?.title,
        description: !!location?.profile?.description,
        websiteUri: !!location?.websiteUri,
        primaryCategory: !!location?.categories?.primaryCategory?.displayName,
        additionalCategories: !!(location?.categories?.additionalCategories?.length > 0),
        location: !!(location?.storefrontAddress?.addressLines?.[0] &&
          location?.storefrontAddress?.locality &&
          location?.storefrontAddress?.administrativeArea),
        regularHours: !!(location?.regularHours?.periods?.length > 0),
      };

      // Calculate total
      const totalFields = Object.keys(fields).length; // Will be 7
      const filledFields = Object.values(fields).filter(Boolean).length;
      const total = Math.round((filledFields / totalFields) * 100);

      return {
        total,
        fields
      };
    },

    formatFieldName(field) {
      return field
        .replace(/([A-Z])/g, ' $1')
        .toLowerCase()
        .replace(/^./, str => str.toUpperCase());
    },

    formatCategory(category) {
      return category
        .split(/(?=[A-Z])/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },

    getProfileStrengthColor(location) {
      const strength = this.getProfileStrength(location).total;
      if (strength >= 85) return '#4CAF50'; // Dark green
      if (strength >= 70) return '#8BC34A'; // Light green
      if (strength >= 50) return '#FFA726'; // Orange
      if (strength >= 30) return '#FF7043'; // Light red
      return '#EF5350'; // Dark red
    },
    checkUpdateEligibility(lastUpdated) {
      if (!lastUpdated) return { canUpdate: true, remainingTime: 0 };

      const now = new Date();
      const lastUpdate = lastUpdated instanceof Date ? lastUpdated : lastUpdated.toDate();
      const diffHours = (now - lastUpdate) / (1000 * 60 * 60);
      const remainingHours = Math.max(0, Math.ceil(this.updateTimeLimit - diffHours));

      return {
        canUpdate: diffHours >= this.updateTimeLimit,
        remainingTime: remainingHours
      };
    },

    async generateDescription(location) {

      location.generatingDescription = true;
      try {
        const completeAddress = [
          location?.storefrontAddress?.addressLines?.[0],
          location?.storefrontAddress?.locality,
          location?.storefrontAddress?.administrativeArea,
          location?.storefrontAddress?.postalCode,
          location?.storefrontAddress?.regionCode
        ].filter(Boolean).join(', ');

        const primaryCategory = location.categories?.primaryCategory?.displayName || '';
        const additionalCategories = location.categories?.additionalCategories?.map(cat => cat.displayName) || [];
        const allCategories = [primaryCategory, ...additionalCategories].filter(Boolean).join(', ');

        const payload = {
          uid: this.currentUID,
          category: allCategories,
          wid: this.WID,
          operation: 'generategmbKeywords',
          textContent: completeAddress || '',
          businessDescription: location?.profile?.description || '',
          brandName: location.title,
          keywords: location.keywords // Add keywords to payload
        };

        const generateKeywords = httpsCallable(functions, "aiActions");
        const res = await generateKeywords(payload);

        location.newDescription = res?.data?.result?.output[0] || '';

      } catch (error) {
        console.error('Error generating description:', error);
        this.$alert.show('Error generating description');
      } finally {
        location.generatingDescription = false;
      }
    },

    async applyNewDescription(location) {
      location.applyingDescription = true;
      try {
        // Initialize profile object if it doesn't exist
        if (!location?.profile) {
          location.profile = {};
        }

        // Update the description
        location.profile.description = location?.newDescription;
        location.newDescription = null;
        this.$snackbar.show('Description updated successfully');
      } catch (error) {
        console.error('Error applying new description:', error);
        this.$alert.show('Error applying new description');
      } finally {
        location.applyingDescription = false;
      }
    },

    async applyBulkUpdates() {
      this.bulkUpdating = true;
      try {
        const updates = this.locations.map(location => {
          const updateFields = {};

          // Check both individual and bulk update eligibility
          if (!this.getLocationUpdateEligibility(location).canUpdate) {
            return null;
          }

          // Add website if changed
          if (this.bulkUpdate.websiteUri && this.bulkUpdate.websiteUri !== location.websiteUri) {
            updateFields.websiteUri = this.bulkUpdate.websiteUri;
          }

          // Add phone if changed
          if (this.bulkUpdate.phoneNumber && this.bulkUpdate.phoneNumber !== location.phoneNumbers?.primaryPhone) {
            updateFields.phoneNumbers = {
              primaryPhone: this.bulkUpdate.phoneNumber
            };
          }

          // Add description if changed
          if (this.bulkUpdate.description && this.bulkUpdate.description !== location?.profile?.description) {
            updateFields.profile = {
              description: this.bulkUpdate.description
            };
          }

          // Only return if there are fields to update
          return Object.keys(updateFields).length > 0 ? {
            locationId: location.name,
            updateFields,
            wid: location.workspaceId,
            accountId: location.accountId
          } : null;
        }).filter(Boolean); // Remove null entries

        if (updates.length === 0) {
          this.$snackbar.show('No changes to update');
          return;
        }

        const payload = {
          uid: this.currentUID,
          updates
        };
        const updateGMBProfile = httpsCallable(functions, "updateGMBDataFunction");
        const res = await updateGMBProfile(payload);

        if (res?.data?.results) {
          // Update the bulk update timestamp
          const currDate = new Date();
          const workspaceRef = doc(db, `users/${this.currentUID}/fly_workspaces/${this.WID}`);
          await updateDoc(workspaceRef, {
            gmb_bulk_last_updated: currDate
          });

          this.$snackbar.show('Bulk updates applied successfully');
        }
      } catch (error) {
        console.error('Error applying bulk updates:', error);
        this.$alert.show('Error updating locations');
      } finally {
        this.bulkUpdating = false;
      }
    },

    getFieldStatus(location) {
      return {
        basicInfo: {
          businessName: !!location?.title,
          description: !!location?.profile?.description,
          websiteUri: !!location?.websiteUri,
          category: !!location.categories?.primaryCategory?.displayName,
          additionalCategories: !!(location.categories?.additionalCategories?.length > 0),
          location: !!(location?.storefrontAddress?.addressLines?.[0] &&
            location?.storefrontAddress?.locality &&
            location?.storefrontAddress?.administrativeArea),
          regularHours: !!(location?.regularHours?.periods?.length > 0),
        }
      };
    },

    formatFieldName(field) {
      const nameMap = {
        businessName: 'Business Name',
        description: 'Description',
        websiteUri: 'Website URL',
        category: 'Primary Category',
        additionalCategories: 'Additional Categories',
        location: 'Location',
        regularHours: 'Regular Hours'
      };
      return nameMap[field] || field;
    },

    getFilterCount(filterValue) {
      return this.locations.filter(location => {
        const strength = this.getProfileStrength(location).total;
        const filter = this.strengthFilters.find(f => f.value === filterValue);
        return filter.condition(strength);
      }).length;
    },

    getGMBUrl(location) {
      // Extract the location ID from the name
      // The name usually follows the pattern: locations/123456789
      const locationId = location.name.split('/').pop();

      // Construct the correct Google Business Profile URL
      return `https://business.google.com/u/0/dashboard/l/${locationId}`;
      // Alternative format if the above doesn't work:
      // return `https://business.google.com/locations/${locationId}`;
    },

    // Helper method to format hours for GMB API
    formatBusinessHours(hours) {
      const periods = [];

      this.weekDays.forEach(({ value }) => {
        if (hours[value].isOpen) {
          // Split hours and minutes
          const [openHour, openMinute] = hours[value].open.split(':');
          const [closeHour, closeMinute] = hours[value].close.split(':');

          periods.push({
            openDay: value.toUpperCase(),
            openTime: {
              hours: parseInt(openHour),
              minutes: parseInt(openMinute)
            },
            closeDay: value.toUpperCase(),
            closeTime: {
              hours: parseInt(closeHour),
              minutes: parseInt(closeMinute)
            }
          });
        }
      });

      return { periods };
    },

    async generateBulkDescription() {

      this.generatingBulkDescription = true;
      try {
        const firstLocation = this.locations[0];
        if (!firstLocation) {
          throw new Error('No locations available');
        }

        const primaryCategory = firstLocation?.categories?.primaryCategory?.displayName || '';
        const additionalCategories = firstLocation?.categories?.additionalCategories?.map(cat => cat.displayName) || [];
        const allCategories = [primaryCategory, ...additionalCategories].filter(Boolean).join(', ');

        const payload = {
          uid: this.currentUID,
          category: allCategories,
          wid: this.WID,
          operation: 'generategmbKeywords',
          textContent: '',
          businessDescription: '',
          brandName: firstLocation?.title,
          keywords: this.bulkKeywords // Add keywords to payload
        };

        const generateKeywords = httpsCallable(functions, "aiActions");
        const res = await generateKeywords(payload);

        if (res?.data?.result?.output?.[0]) {
          this.bulkUpdate.description = res.data.result.output[0];
          this.$snackbar.show('Description generated successfully');
        } else {
          throw new Error('Failed to generate description');
        }
      } catch (error) {
        console.error('Error generating bulk description:', error);
        this.$alert.show(error.message || 'Error generating description');
      } finally {
        this.generatingBulkDescription = false;
      }
    },

    async addKeyword(location) {
      const keyword = location.currentKeyword.trim();
      if (keyword && !location.keywords.includes(keyword)) {
        location.keywords.push(keyword);
        location.currentKeyword = '';

        // Update Firestore
        const workspaceDoc = doc(db, `users/${this.currentUID}/fly_workspaces/${location.workspaceId}`);
        await updateDoc(workspaceDoc, {
          gmbKeywords: arrayUnion(keyword)
        });
      }
    },

    async removeKeyword(location, index) {
      const keyword = location.keywords[index];
      location.keywords.splice(index, 1);

      // Update Firestore
      const workspaceDoc = doc(db, `users/${this.currentUID}/fly_workspaces/${location.workspaceId}`);
      await updateDoc(workspaceDoc, {
        gmbKeywords: arrayRemove(keyword)
      });
    },

    addBulkKeyword() {
      const keyword = this.currentBulkKeyword.trim();
      if (keyword && !this.bulkKeywords.includes(keyword)) {
        this.bulkKeywords.push(keyword);
      }
      this.currentBulkKeyword = '';
    },

    removeBulkKeyword(index) {
      this.bulkKeywords.splice(index, 1);
    },

    // Add this new method
    getLocationUpdateEligibility(location) {
      // Check individual location update time
      const locationEligibility = this.checkUpdateEligibility(location.gmb_last_updated);

      // Check bulk update time
      const bulkEligibility = this.checkUpdateEligibility(this.gmb_bulk_last_updated);

      // Return the longer waiting period
      return {
        canUpdate: locationEligibility.canUpdate && bulkEligibility.canUpdate,
        remainingTime: Math.max(locationEligibility.remainingTime, bulkEligibility.remainingTime)
      };
    },

    filteredAdditionalCategories(location) {
      if (!this.categories || !location.categorySearchQuery) return [];

      const searchQuery = location.categorySearchQuery.toLowerCase();

      // Filter out the primary category and already selected additional categories
      return this.categories.filter(category => {
        const isPrimary = category.name === location.categories?.primaryCategory?.name;
        const isAlreadySelected = location.formData.additionalCategories?.some(
          selected => selected.name === category.name
        );
        const matchesSearch = category.displayName.toLowerCase().includes(searchQuery);
        return !isPrimary && !isAlreadySelected && matchesSearch;
      });
    },

    addAdditionalCategory(location) {
      if (!location.selectedAdditionalCategory) return;

      if (!location.formData.additionalCategories) {
        location.formData.additionalCategories = [];
      }

      if (location.formData.additionalCategories.length >= 9) {
        this.$alert.show('Maximum 9 additional categories allowed');
        return;
      }

      const categoryToAdd = this.categories.find(
        cat => cat.name === location.selectedAdditionalCategory
      );

      if (categoryToAdd) {
        location.formData.additionalCategories.push({
          ...categoryToAdd,
          isOriginal: false // Mark as new category
        });
      }

      // Reset selection
      location.selectedAdditionalCategory = null;
    },

    removeAdditionalCategory(location, index) {
      location.formData.additionalCategories.splice(index, 1);
    },

    arraysEqual(a, b) {
      if (a.length !== b.length) return false;
      for (let i = 0; i < a.length; i++) {
        if (a[i].name !== b[i].name) return false;
      }
      return true;
    },

    async suggestCategories(location) {
      location.suggestingCategories = true;
      console.log(location.categories, "all categories");
      const primaryCategory = location?.categories?.primaryCategory?.name || null;
      const additionalCategories = location?.categories?.additionalCategories?.map(cat => cat.name) || [];
      // return ;
      try {
        const payload = {
          uid: this.currentUID,
          wid: this.WID,
          additionalCategories: additionalCategories,
          primaryCategory: primaryCategory,
          operation: 'suggestGMBCategories',
          allCategories: this.categoriesId,
          businessDescription: location?.profile?.description || '',
          brandName: location?.title,
        };
        // console.log(payload,"this is the payload");
        // return ;
        const suggestCategories = httpsCallable(functions, "aiActions");
        const res = await suggestCategories(payload);
        // console.log(res.data.result.output,'these are additional category output');
        // return ;
        if (res?.data?.result?.output) {
          // Parse the suggested categories and add them
          const suggestions = res.data.result.output.suggested_categories;
          for (let suggestion of suggestions) {
            suggestion = `categories/gcid:${suggestion}`
            const matchingCategory = this.categories.find(
              cat => cat.name.toLowerCase() === suggestion.toLowerCase()
            );

            if (matchingCategory &&
              !location.formData.additionalCategories.some(cat => cat.name === matchingCategory.name) &&
              location.formData.additionalCategories.length < 9) {
              location.formData.additionalCategories.push({
                ...matchingCategory,
                isOriginal: false
              });
            }
          }
          this.$snackbar.show('Categories suggested successfully');
        }
      } catch (error) {
        console.error('Error suggesting categories:', error);
        this.$alert.show('Error suggesting categories');
      } finally {
        location.suggestingCategories = false;
      }
    },

    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        // Fetch GMB data only if the current page data is not already loaded
      }
    },

    async prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        // Fetch GMB data only if the current page data is not already loaded
      }
    },
  }
};
</script>

<style scoped>
.form-group {
  @apply space-y-2;
}

.form-label {
  @apply block text-sm font-medium text-gray-700;
}

.form-input {
  @apply w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary;
}

/* Add specific shadow for phone and business name inputs */
form .form-input {
  @apply shadow-md;
}

.category-select {
  max-width: 400px;
}

/* Override Vuetify styles */
:deep(.v-text-field__details) {
  display: none;
}

:deep(.v-messages) {
  display: none;
}

/* Add these styles to match the existing input styling */
select.form-input {
  @apply appearance-none bg-no-repeat bg-right;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
}

/* Add these styles for smooth transitions */
button {
  @apply transform hover:scale-105;
}

/* Status Filter Styles */
.status-filters {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.status-filter-btn {
  padding: 0.375rem 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  transition: all 0.2s;
  background-color: white;
  color: #4a5568;
}

.status-filter-btn:hover {
  opacity: 0.9;
}

/* Excellent - Green */
.status-filter-btn.status-excellent {
  border-color: #4CAF50;

  &.active {
    background-color: #4CAF50;
    color: white;
  }

  &:not(.active) {
    color: #4CAF50;
    background-color: #e8f5e9;
  }
}

/* Good - Light Green */
.status-filter-btn.status-good {
  border-color: #8BC34A;

  &.active {
    background-color: #8BC34A;
    color: white;
  }

  &:not(.active) {
    color: #8BC34A;
    background-color: #f1f8e9;
  }
}

/* Average - Orange */
.status-filter-btn.status-average {
  border-color: #FFA726;

  &.active {
    background-color: #FFA726;
    color: white;
  }

  &:not(.active) {
    color: #FFA726;
    background-color: #fff3e0;
  }
}

/* Poor - Light Red */
.status-filter-btn.status-poor {
  border-color: #FF7043;

  &.active {
    background-color: #FF7043;
    color: white;
  }

  &:not(.active) {
    color: #FF7043;
    background-color: #fbe9e7;
  }
}

/* Critical - Red */
.status-filter-btn.status-critical {
  border-color: #EF5350;

  &.active {
    background-color: #EF5350;
    color: white;
  }

  &:not(.active) {
    color: #EF5350;
    background-color: #ffebee;
  }
}

/* All filter button */
.status-filter-btn:first-child {
  border-color: #9ca3af;

  &.active {
    background-color: #9ca3af;
    color: white;
  }

  &:not(.active) {
    color: #4a5568;
    background-color: #f3f4f6;
  }
}

.status-filters {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

/* Add these styles for the phone input */
.form-input.pl-12 {
  padding-left: 3rem;
}

:deep(.vue-tel-input-vuetify .v-input.v-text-field) {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

/* If you need to target all possible variations */
:deep(.vue-tel-input-vuetify) {

  .v-text-field,
  .v-input,
  .v-input.v-text-field {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
}

.keyword-chip {
  @apply bg-gray-100 px-2 py-1 rounded-md flex items-center gap-1 text-sm;
}

.keyword-chip button {
  @apply text-gray-500 hover:text-red-500;
}

/* Add these new styles */
.keyword-chip {
  @apply bg-gray-100 px-2 py-1 rounded-full flex items-center justify-between gap-1;
  min-width: 60px;
  /* Minimum width for very short keywords */
  transition: width 0.2s ease;
}

.keyword-chip span {
  @apply flex-1 text-center;
}

/* Add this with your other styles */
.category-chip-new {
  border: 1px solid #8050DE !important;
  color: #8050DE !important;
}

.pagination-button {
  @apply bg-blue-500 text-white font-semibold py-2 px-4 rounded border border-blue-600 transition duration-200;
}

.pagination-button:hover {
  @apply bg-blue-600;
}

.pagination-button:disabled {
  @apply bg-gray-300 text-gray-500 cursor-not-allowed;
}
</style>
