<template>
  <div>
    <!--  -->
    <div class="w-full items-center mx-auto px-4 my-8">
      <div class="pb-0 mb-8 flex">
        <p class="mt-2 text-lg font-semibold text-[#121212]">
          Competitor Analysis
        </p>
      </div>
      <div
        class="relative flex justify-between flex-col md:flex-row items-start gap-6 md:items-center md:gap-2 grow"
      >
        <div class="gap-2 w-full md:w-1/3">
          <!-- :value="selectedLocationDisplay || locationSearch" -->
          <div class="relative">
            <v-text-field
              v-model="locationSearch"
              :placeholder="selectedLocationDisplay || 'Search locations...'"
              label="Locations"
              hide-details
              dense
              outlined
              @click="toggleStoreDropdown($event)"
              @input="handleInputChange"
              class="location-input"
              :class="
                selectedWID ? 'highlight-placeholder' : 'highlight-border'
              "
              color="rgba(0, 0, 0, 0.6)"
              background-color="white"
              persistent-placeholder
              append-icon="mdi-chevron-down"
              @click:append="toggleStoreDropdown($event)"
            >
            </v-text-field>
            <div
              ref="showStoreDropdown"
              v-if="showStoreDropdown"
              class="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-50 max-h-60 max-w-[800px] overflow-y-auto px-5"
            >
              <div
                v-for="profile in filteredStores"
                :key="profile.wid"
                class="platform p-2"
              >
                <div class="flex items-center gap-2">
                  <input
                    type="radio"
                    :value="profile.wid"
                    v-model="selectedWID"
                    @change="handleLocationSelect(profile)"
                  />
                  <div class="flex flex-col">
                    <span
                      class="font-medium text-base"
                      v-if="profile.sub_locality"
                      >{{ profile.sub_locality }}</span
                    >
                    <span class="text-sm">{{ profile.city }}</span>
                    <span class="text-sm">{{ profile.state }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-btn
          color="primary"
          :loading="isLoadingCompetitors"
          :disabled="!selectedWID"
          @click="generateCompetitorAnalysis"
          class="ml-2 px-5"
        >
          Generate
        </v-btn>
      </div>
      <div class="flex gap-8 mb-4 mt-8">
        <button
          @click="currentView = 'all'"
          class="tabs px-4 py-2 rounded-full text-sm"
          :class="{ active: currentView === 'all' }"
        >
          Across all keywords
        </button>
        <button
          @click="currentView = 'byKeyword'"
          class="tabs px-4 py-2 rounded-full text-sm"
          :class="{ active: currentView === 'byKeyword' }"
        >
          By keyword
        </button>
      </div>
    </div>

    <template v-if="isLoadingCompetitors">
      <v-skeleton-loader
        type="table"
        class="mb-4"
        boilerplate
      ></v-skeleton-loader>
    </template>
    <template v-else-if="!selectedWID">
      <div
        class="w-full flex items-center gap-1 justify-center mx-auto px-4 mt-4 text-gray-500 text-center"
      >
        No location selected <v-icon size="20">mdi-alert-circle-outline</v-icon>
      </div>
    </template>
    <template
      v-else-if="
        processedCompetitors?.length &&
        !isLoadingCompetitors &&
        currentView === 'all'
      "
    >
      <div class="px-3 mx-2 my-8 w-full">
        <div class="bg-white p-4 rounded-lg shadow-md mt-4 w-[98%]">
          <div class="overflow-x-auto">
            <div class="min-w-[800px]">
              <div class="bg-gray-100 rounded-t-lg text-base w-full">
                <div class="flex items-center text-gray-600 p-2">
                  <div
                    class="w-1/3 text-start px-2 cursor-pointer hover:bg-gray-200"
                  >
                    Business Name
                  </div>
                  <div
                    class="w-1/6 text-center px-2 cursor-pointer hover:bg-gray-200"
                  >
                    Avg. Rank
                  </div>
                  <div
                    class="w-1/6 text-center px-2 cursor-pointer hover:bg-gray-200"
                  >
                    Rating
                  </div>
                  <div
                    class="w-1/6 text-center px-2 cursor-pointer hover:bg-gray-200"
                  >
                    Total Ratings
                  </div>
                  <div class="w-1/6 text-start px-2">Category</div>
                </div>
              </div>

              <!-- Sample static data -->
              <div
                v-for="(competitor, index) in processedCompetitors"
                :key="index"
                class="border-b border-gray-200"
              >
                <div>
                  <div
                    class="flex items-center w-full text-sm bg-white hover:bg-gray-100 py-3 cursor-pointer px-2"
                    @click="toggleCompetitorDetails(competitor.name)"
                  >
                    <div class="w-1/3 px-2">
                      <div class="flex items-center gap-2">
                        <span
                          class="font-medium"
                          :class="{
                            'text-blue-500 font-semibold':
                              competitor.owner,
                          }"
                          >{{ competitor.name }}</span
                        >
                        <v-icon small>
                          {{
                            isCompetitorExpanded(competitor.name)
                              ? "mdi-chevron-up"
                              : "mdi-chevron-down"
                          }}
                        </v-icon>
                      </div>
                    </div>
                    <div class="w-1/6 text-center font-medium">
                      {{ competitor.avgRank }}
                    </div>
                    <div class="w-1/6 text-center">
                      <div class="flex items-center justify-center gap-1">
                        <span class="font-medium">{{ competitor.rating }}</span>
                        <v-icon small color="amber">mdi-star</v-icon>
                      </div>
                    </div>
                    <div class="w-1/6 text-center font-medium">
                      {{ competitor.totalRatings }}
                    </div>
                    <div class="w-1/6 text-start px-2 text-gray-600">
                      {{ competitor.category }}
                    </div>
                  </div>

                  <!-- Keyword-specific ranks dropdown -->
                  <div
                    v-if="isCompetitorExpanded(competitor.name)"
                    class="bg-gray-50 px-4 py-2 text-sm w-full"
                  >
                    <div
                      v-if="isCompetitorExpanded(competitor.name)"
                      class="w-full md:w-3/4"
                    >
                      <div class="font-medium mb-2">Keyword Rankings:</div>
                      <div
                        v-for="(keywordRank, idx) in competitor.keywordRanks"
                        :key="idx"
                        class="grid grid-cols-2 gap-4 py-1"
                      >
                        <div>{{ keywordRank.keyword }}</div>
                        <div class="flex items-center gap-2">
                          <span>Rank: {{ keywordRank.rank }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- By keyword view -->
    <template
      v-if="
        currentView === 'byKeyword' &&
        processedCompetitors?.length &&
        !isLoadingCompetitors
      "
    >
      <div class="px-3 mx-2 my-8 w-full">
        <div class="bg-white p-4 rounded-lg shadow-md mt-4 w-[98%]">
          <div class="overflow-x-auto">
            <div class="min-w-[800px]">
              <!-- Header -->
              <div class="bg-gray-100 rounded-t-lg text-base w-full">
                <div class="flex items-center text-gray-600 p-2">
                  <div class="w-1/3 text-start px-2">Keyword</div>
                  <div class="w-1/6 text-center px-2">Rank</div>
                  <div class="w-1/6 text-center px-2">Rating</div>
                  <div class="w-1/6 text-center px-2">Total Ratings</div>
                  <div class="w-1/6 text-start px-2">Category</div>
                </div>
              </div>

              <!-- Keywords list -->
              <div
                v-for="keyword in uniqueKeywords"
                :key="keyword"
                class="border-b border-gray-200"
              >
                <div>
                  <!-- Keyword row -->
                  <div
                    class="flex items-center w-full text-sm bg-white hover:bg-gray-100 py-3 cursor-pointer"
                    @click="toggleKeywordDetails(keyword)"
                  >
                    <div class="w-1/3 px-2">
                      <div class="flex items-center gap-2">
                        <span class="font-medium">{{ keyword }}</span>
                        <v-icon small>
                          {{
                            isKeywordExpanded(keyword)
                              ? "mdi-chevron-up"
                              : "mdi-chevron-down"
                          }}
                        </v-icon>
                      </div>
                    </div>
                    <div class="w-1/6 text-center">--</div>
                    <div class="w-1/6 text-center">--</div>
                    <div class="w-1/6 text-center">--</div>
                    <div class="w-1/6 text-start px-2">--</div>
                  </div>

                  <!-- Businesses for this keyword -->
                  <div
                    v-if="isKeywordExpanded(keyword)"
                    class="bg-gray-50 px-4 py-2 text-sm"
                  >
                    <div
                      v-for="business in getBusinessesByKeyword(keyword)"
                      :key="business.name"
                      class="flex items-center w-full py-2"
                    >
                      <div class="w-1/3 px-2">
                        <span
                          class="font-medium"
                          :class="{
                            'text-blue-500 font-semibold':
                              business.owner,
                          }"
                          >{{ business.name }}</span
                        >
                      </div>
                      <div class="w-1/6 text-center">{{ business.rank }}</div>
                      <div
                        class="w-1/6 text-center flex items-center justify-center gap-1"
                      >
                        <span>{{ business.rating }}</span>
                        <v-icon small color="amber">mdi-star</v-icon>
                      </div>
                      <div class="w-1/6 text-center">
                        {{ business.totalRatings }}
                      </div>
                      <div class="w-1/6 text-start px-2">
                        {{ business.category }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template
      v-else-if="
        !processedCompetitors?.length &&
        !isLoadingCompetitors &&
        selectedWID &&
        startedGenerating
      "
    >
      <div
        class="w-full flex items-center gap-1 justify-center mx-auto px-4 mt-4 text-gray-500 text-center"
      >
        No data found
      </div>
    </template>
  </div>
</template>

<script>
import { collection, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";
import { auth, db } from "@/utils/firebase.utils";
import ToolTip from "@/components/ui/ToolTip.vue";

export default {
  components: { ToolTip },
  props: {},
  data() {
    return {
      stores: [],
      selectedStore: null,
      showStoreDropdown: false,
      selectedWID: null,
      isLoading: false,
      sortBy: "location",
      sortDesc: false,
      selectedCompetitorId: null,
      processedCompetitors: [],
      isLoadingCompetitors: false,
      locationSearch: "",
      selectedLocationDisplay: "",
      startedGenerating: false,
      currentView: "all",
      expandedKeywords: new Set(),
      expandedCompetitors: new Set(), // Add this line
    };
  },
  computed: {
    filteredStores() {
      if (!this.locationSearch) {
        return this.stores;
      }

      const searchTerm = this.locationSearch.toLowerCase();
      return this.stores.filter(
        (store) =>
          store.name?.toLowerCase().includes(searchTerm) ||
          store.sub_locality?.toLowerCase().includes(searchTerm) ||
          store.city?.toLowerCase().includes(searchTerm) ||
          store.state?.toLowerCase().includes(searchTerm)
      );
    },
    uniqueKeywords() {
      return (
        this.processedCompetitors?.[0]?.keywordRanks?.map((kr) => kr.keyword) ||
        []
      );
    },
  },
  watch: {},
  async created() {},
  methods: {
    handleInputChange(value) {
      // Clear selection when user starts typing
      if (this.selectedWID) {
        this.selectedWID = null;
        this.selectedStore = null;
        this.selectedLocationDisplay = "";
      }
      this.locationSearch = value;

      // Open dropdown if it's not already open
      if (!this.showStoreDropdown) {
        this.showStoreDropdown = true;
      }
    },
    toggleKeywordDetails(keyword) {
      if (this.expandedKeywords.has(keyword)) {
        this.expandedKeywords.delete(keyword);
      } else {
        this.expandedKeywords.add(keyword);
      }
      // Force reactivity update
      this.expandedKeywords = new Set(this.expandedKeywords);
    },

    isKeywordExpanded(keyword) {
      return this.expandedKeywords.has(keyword);
    },

    getBusinessesByKeyword(keyword) {
      return this.processedCompetitors
        .filter((comp) =>
          comp.keywordRanks.some((kr) => kr.keyword === keyword)
        )
        .map((comp) => ({
          name: comp.name,
          rank:
            comp.keywordRanks.find((kr) => kr.keyword === keyword)?.rank ||
            "--",
          rating: comp.rating,
          owner: comp.owner || false,
          totalRatings: comp.totalRatings,
          category: comp.category,
        }))
        .sort((a, b) => {
          // Convert to numbers for sorting, handle '--' cases
          const rankA = a.rank === "--" ? Infinity : Number(a.rank);
          const rankB = b.rank === "--" ? Infinity : Number(b.rank);
          return rankA - rankB;
        });
    },
    processCompetitors(data) {
      // console.log("data::", data);
      // Store the keywordsArray order
      const keywordsArray = data.data.keywordsArray;
      this.processedCompetitors = data.data.competitors.map((comp) => {
        // Transform all rankings into keywordRanks array
        const keywordRanks = Object.entries(comp.rankings).map(
          ([keyword, rank]) => ({
          keyword,
          rank,
        })
      );
        // Create keywordRanks array in the same order as keywordsArray
        const keywordOriginalOrder = keywordsArray.map((keyword) => ({
          keyword,
          rank: comp.rankings[keyword] || "--",
        }));

        // Format averageRank to 1 decimal place
        const formattedAvgRank = comp.averageRank
          ? Number(comp.averageRank).toFixed(1)
          : "--";
        // Format category array into a readable string
        const formattedCategory = Array.isArray(comp.type)
          ? comp.type.join(", ")
          : comp.type || "Unknown";

        return {
          name: comp.name,
          rating: comp.averageRating || "--",
          totalRatings: comp.totalReviews || "--",
          category: formattedCategory,
          address: comp.address,
          owner: comp.isMyBusiness || false,
          avgRank: formattedAvgRank,
          keywordRanks, // Include all keyword rankings
          ordered_keywords: keywordOriginalOrder
        };
      });

      // Sort by average rank
      this.processedCompetitors.sort(
        (a, b) => parseFloat(a.avgRank) - parseFloat(b.avgRank)
      );
      console.log("processedCompetitors:", this.processedCompetitors);
    },
    toggleCompetitorDetails(name) {
      if (this.expandedCompetitors.has(name)) {
        this.expandedCompetitors.delete(name);
      } else {
        this.expandedCompetitors.add(name);
      }
      // Force reactivity update
      this.expandedCompetitors = new Set(this.expandedCompetitors);
    },

    isCompetitorExpanded(name) {
      return this.expandedCompetitors.has(name);
    },
    handleLocationSelect(profile) {
      this.selectedStore = profile;
      this.startedGenerating = false;
      this.processedCompetitors = [];
      this.selectedLocationDisplay = [
        profile.sub_locality,
        profile.city,
        profile.state,
      ]
        .filter(Boolean)
        .join(", ");
      this.locationSearch = ""; // Clear the search text
      this.showStoreDropdown = false;
    },
    async generateCompetitorAnalysis() {
      if (!this.selectedWID || !this.selectedStore) return;

      try {
        this.startedGenerating = true;
        this.isLoadingCompetitors = true;
        this.$loader.show();

        const uid = this.currentUID;
        const payload = {
          uid,
          wid: this.selectedWID,
        };

        const fetchCompetitors = httpsCallable(functions, "fetchCompetitors", {
          timeout: 540000,
        });

        const result = await fetchCompetitors(payload);
        if (result && !result.data?.status) {
          this.$snackbar.show({
            message: result.data.message || 'Something went wrong',
            color: "error",
          });
          this.processedCompetitors = [];
        } else if (result && result.data) {
          // console.log('result:', result)
          this.processCompetitors(result.data);
        } else {
          console.warn("No competitor data received");
          this.processedCompetitors = [];
        }
      } catch (error) {
        // console.error("Error fetching competitor data:", error);
        // console.log(error, error.message)
        this.$snackbar.show({
            message: error?.message || 'Something went wrong',
            color: "error",
          });
        this.processedCompetitors = [];
      } finally {
        this.isLoadingCompetitors = false;
        this.$loader.hide();
      }
    },
    closeProfileDropdown() {
      if (this.showStoreDropdown) {
        this.showStoreDropdown = false;
      }
    },
    toggleStoreDropdown(event) {
      this.showStoreDropdown = !this.showStoreDropdown;
      event.stopPropagation();
    },
    async fetchStores() {
      const uid = this.currentUID;
      const storeRef = collection(db, "users", uid, "fly_workspaces");
      onSnapshot(storeRef, async (querySnapshot) => {
        this.stores = [];
        querySnapshot.forEach((doc) => {
          let storeData = doc.data();
          if (
            storeData.storeWorkspace &&
            storeData.admin === this.WID &&
            storeData.connected_apps?.includes("google_business_profile")
          ) {
            storeData.wid = doc.id;
            storeData.type = "store";
            storeData.display_name = `${storeData.sub_locality || ""} - ${
              storeData.name || ""
            }`;
            this.stores.push(storeData);
          }
        });
      });
    },
    async fetchCompetitorData(store) {
      try {
        if (!store || !store.wid) {
          console.error("Invalid store data");
          return;
        }
        this.isLoadingCompetitors = true;
        this.$loader.show();
        const uid = this.currentUID;
        const payload = {
          uid,
          wid: store.wid,
        };

        const fetchCompetitorsData = httpsCallable(
          functions,
          "fetchCompetitors",
          {
            timeout: 540000,
          }
        );
        const result = await fetchCompetitorsData(payload);
        console.log("result:", result.data);
        console.log("resultd:", result.data.data);

        // Ensure we have data before assigning
        if (result && result.data) {
          this.competitors = result.data;
          this.processCompetitors(result.data);
        } else {
          console.warn("No competitor data received");
          this.competitors = []; // Set empty array as fallback
        }
      } catch (error) {
        console.error("Error fetching competitor data:", error);
        this.competitors = []; // Set empty array on error
      } finally {
        this.isLoadingCompetitors = false;
        this.$loader.hide();
      }
    },
  },

  async mounted() {
    // this.fetchGMBLogo();
    await this.fetchStores();
    // this.fetchChartMetrics();
  },
};
</script>

<style scoped>
.text-xs {
  font-size: 0.75rem;
}
:deep(.v-text-field.location-input) {
  .v-input__slot {
    min-height: 40px !important;
  }

  .v-text-field__placeholder {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }

  input {
    font-size: 14px;
  }

  fieldset {
    border-color: rgba(0, 0, 0, 0.6) !important;
  }
}
:deep(.v-text-field.location-input.highlight-placeholder input::placeholder) {
  color: black !important;
  font-size: 14px;
}
/* Target the label specifically */
:deep(.v-text-field.location-input .v-label) {
  font-size: 20px !important;
}

:deep(.v-text-field.location-input .v-label.v-label--active) {
  font-size: 20px !important;
  transform: translateY(-20px) scale(0.75) !important;
}

/* Target both normal and active states */
:deep(.v-text-field.location-input) .v-label,
:deep(.v-text-field.location-input) .v-label--active {
  font-size: 20px !important;
  font-weight: 500 !important;
}

/* Ensure the label stays visible in all states */
:deep(.v-text-field--outlined.location-input) .v-label {
  top: 10px !important;
  font-size: 20px !important;
}

:deep(.v-text-field--outlined.location-input) .v-label--active {
  transform: translateY(-20px) scale(0.75) !important;
}

img {
  object-fit: cover;
}
.cursor-pointer {
  cursor: pointer;
}
.hover\:bg-gray-100:hover {
  background-color: #f7fafc;
}
.leading-tight {
  line-height: 1.25;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
}
.skeleton-loader {
  display: inline-block;
  width: 50px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite;
}
.overflow-x-auto {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}
/* Optional: Custom scrollbar styling */
.overflow-x-auto::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.overflow-x-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tooltip-container {
  position: relative;
  z-index: 40;
}
.v-tooltip__content {
  z-index: 60 !important;
  position: fixed !important;
}
.v-icon {
  z-index: 40;
  position: relative;
}

.showStoreDropdown {
  background-color: white; /* Ensure solid background */
  z-index: 50;
  position: relative;
}

.date-select {
  position: relative;
  z-index: 40;
}

button.tabs {
  border-width: 2px;
  border-style: solid;
  border-color: #8056de;
}
button.tabs.active {
  background-color: #8056de;
  color: white;
}
.metrics-select {
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* This ensures the v-select dropdown menu appears above everything */
:deep(.v-menu__content) {
  z-index: 60 !important;
}
</style>
